/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #ffffff;
  scroll-behavior: smooth;
  scroll-padding: 10rem;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: "Poppins";
  overflow-x: hidden;
}